import React, { ChangeEvent, FC, FormEvent, useEffect } from 'react'
import styles from "../../assets/styles/chat.module.css"
import { ChatFileIcon, ChatSendIcon, InFoIcon } from '../../utils/functions/Icons'
import { Images } from '../../utils/functions/Images'
import { FormGroup, InputAdornment, TextField } from '@mui/material'
import NoChat from './NoChat'
import { getFileExtension } from '../../utils/functions/common'
interface Props {
    particularUser: ChatPartner | null;
    messages: ChatHistoryItem[] | [];
    handleChangeMsg: (e: ChangeEvent<HTMLInputElement>) => void;
    newMessage: string;
    handleSendMessage: (e: FormEvent<HTMLFormElement>) => void;
    handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
}
const PatientChatBody: FC<Props> = ({
    particularUser,
    messages,
    handleChangeMsg,
    newMessage,
    handleSendMessage,
    handleFileChange
}) => {
    const bodyBoxRef = React.createRef<HTMLDivElement>();

    useEffect(() => {
        if (bodyBoxRef.current) {
            bodyBoxRef.current.scrollTop = bodyBoxRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <div className={styles.bodyMain}>
            {
                particularUser ?
                    <>
                        <div className={styles.bodyHeader}>
                            <div className={styles.bHUser}>
                                <div className={styles.BHUIcon}>
                                    <img src={particularUser?.profilePic ? particularUser?.profilePic : Images.avtarIcon} alt={`${particularUser?.name ? particularUser?.name : Images.avtarIcon}`} />
                                </div>
                                <div className={styles.BhUName}>
                                    <h4>{particularUser?.chatPartnerName}</h4>
                                </div>
                            </div>
                        </div>
                        <div className={styles.bodyBox} ref={bodyBoxRef}>
                            {
                                messages?.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className={styles.chatDateMain}>
                                                <div className={styles.chatDate}>
                                                    <p>{item?.date}</p>
                                                </div>
                                            </div>
                                            {item?.messages.map((msg, id) => {
                                                const fileExtension = msg.messageType === 2 && getFileExtension(msg?.message);
                                                const isImage = msg.messageType === 2 && fileExtension && ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileExtension); // Check if it's an image
                                                return (
                                                    <React.Fragment key={id}>
                                                        {
                                                            msg?.type === "received" ?
                                                                <div className='doctorChat'>
                                                                    <div className='patientImg'>
                                                                        <img src={msg?.profilePic ? msg?.profilePic : Images.avtarIcon} alt="Patient Image" />
                                                                    </div>
                                                                    <div className={`DoctorMsg ${msg?.messageType === 2 ? 'noBgForThis' : ''}`}>
                                                                        {
                                                                            msg?.messageType === 1 ?
                                                                                <p>
                                                                                    {msg?.message}
                                                                                </p>
                                                                                :
                                                                                <>
                                                                                {
                                                                                    isImage ?
                                                                                        <img src={msg?.message} alt={`image${id}`} />
                                                                                        :
                                                                                        <img onClick={() => window.open(msg?.message)} className={styles.fileIcon} src={Images.filesIcon} alt={`file${id}`} />
                                                                                }
                                                                            </>
                                                                        }
                                                                        <span>{msg?.time}</span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='patientChat'>
                                                                    <div className={`patientMsg ${msg?.messageType === 2 ? 'noBgForThis' : ''}`}>
                                                                        {
                                                                            msg?.messageType === 1 ?
                                                                                <p>
                                                                                    {msg?.message}
                                                                                </p>
                                                                                : <>
                                                                                    {
                                                                                        isImage ?
                                                                                            <img src={msg?.message} alt={`image${id}`} />
                                                                                            :
                                                                                            <img onClick={() => window.open(msg?.message)} className={styles.fileIcon} src={Images.filesIcon} alt={`file${id}`} />
                                                                                    }
                                                                                </>
                                                                        }
                                                                        <span>{msg?.time}</span>
                                                                    </div>
                                                                    <div className='patientImg'>
                                                                        <img src={msg?.profilePic ? msg?.profilePic : Images.avtarIcon} alt="Doctor Image" />
                                                                    </div>
                                                                </div>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                        <div className={styles.bodyFooter}>
                            <form onSubmit={handleSendMessage}>
                                <FormGroup className="customFormGroup">
                                    <TextField
                                        className={`custumTextField custumTextFieldChat`}
                                        id="newMessage"
                                        name="newMessage"
                                        placeholder='Message here'
                                        onChange={handleChangeMsg}
                                        value={newMessage}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <div className='fileIcon'>
                                                        <input
                                                            type="file"
                                                            name={'file_upload'}
                                                            id={'file_upload'}
                                                            accept={'.jpeg,.jpg,.png,.txt,.xlsx,.xls,.csv,.pdf,.doc,.docx'}
                                                            multiple={false}
                                                            className="customFileInput"
                                                            onChange={handleFileChange}
                                                            style={{ display: 'none' }}
                                                        />
                                                        <label className={`cursor-pointer`} htmlFor={'file_upload'}>
                                                            <ChatFileIcon />
                                                        </label>
                                                    </div>
                                                    <button type='submit' className='sendMessageBtn ml-4'>
                                                        <ChatSendIcon />
                                                    </button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormGroup>
                            </form>
                        </div>
                    </>
                    :
                    <NoChat description='Here you can contact your doctor or care navigator' />
            }

        </div>
    )
}

export default PatientChatBody