import axios from 'axios';
import { BASE_URL } from './routes';

const axiosNoAuth = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    role: "careNavigator",
  },
});

export default axiosNoAuth;
