export const BASE_URL = "https://patientportalapi.akosmd.in/api"; //development
// export const PATIENT_PORTAL = "http://localhost:3005"
export const PATIENT_PORTAL = "https://360.akosmd.in/"

export const LOGIN = "/auth/login";
export const FORGOT_PASSWORD = "/forgot-password/send-otp";
export const VERIFY_OTP = "/forgot-password/verify-otp";
export const CHANGE_PASSWORD = "/forgot-password/change-password";
export const CHANGE_PASSWORD_INSIDE = "/forgot-password/change_password";
export const RESEND_OTP = "/resend-otp/send";
export const HELP_FAQ = "/help/faq";
export const HELP_SEND_EMAIL = "/careNavigator/send-email";
export const GET_APPOINTMENT = "/careNavigator/appointments";
export const GET_MY_PATIENT = "/careNavigator/myPatients";
export const GET_ALL_PATIENT = "/careNavigator/patients";
export const RESCHEDULE_APPOINTMENT = "/careNavigator/reschedule";
export const CANCEL_APPOINTMENT = "/careNavigator/cancel";
export const APPROVE_APPOINTMENT = "/careNavigator/approve";
export const UPDATE_APPOINTMENT = "/careNavigator/updateAppointment";
export const UPLOAD_LAB_REPORT = "/careNavigator/uploadLabReport";
export const UPLOAD_FILE = "/data/upload";
export const GET_DASHBOARD = "/careNavigator/dashboard";
export const GET_PROFILE = "/careNavigator/profile";
export const GET_LAB_TESTS = "/careNavigator/labTests";
export const UPDATE_LAB_ORDER = "/careNavigator/updateLabOrder";
export const GET_MASTER = "/data/patient";
export const GET_STATE = "/data/states";
export const GET_CITY = "/data/cities";
export const GET_OPENTOK_ROOM_KEYS = "/getOpentokRoomKeys";
export const GENERATE_TOKEN = "/opentok/generate-token";
export const GENERATE_CALLID_BY_PATIENT = "/generateCallIdByPatientId";
export const CHECK_STATUS_WAITING_ROOM = "/checkstatusinwatingroom";
export const GET_SAVED_USER_ID = "/getSavedUserId";
export const ADD_USERTO_WAITING = "/addUserToWaiting";
export const CALL_END_BY_PATIENT = "/callendbypatient";
export const DISCONNECT_REASON = "/updatetcalldisconnectreason";
export const PATIENT_DETAILS_CHECK = "/patient/details";
export const UPDATE_PROFILE_ON_CALL = "/patient/updateProfile";
export const GET_LAB_PRESCRIPTION = "/careNavigator/prescriptions";
export const DELETE_PRES_FILE = "/labs/prescription";
export const GET_PACKAGES = "/careNavigator/getPackages";
export const GET_LAB_DETAILS = "/careNavigator/orders";
export const GET_PATIENT_LOGIN_CREDENTIALS = "/careNavigator/login";
export const CHAT_LIST_USERS = "/chat/chat-list";
export const CHAT_HISTORY = "/chat/chat-history";
export const CHAT_UPDATE_READ_STATUS = "/chat/update-read-status";
export const CHAT_SEARCHLIST = "/chat/searchList";
export const UPLOAD_PRESCRIPTION = "/careNavigator/uploadPrescription";
export const GET_NOTIFICATION = "/notify/notifications";
export const REMOVE_NOTIFICATION = "/notify/removeNotification";
