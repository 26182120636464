import CustomContainer from "../../components/common/CustomContainer"
import styles from "../../assets/styles/appointments.module.css"
import { Tab, Tabs } from "@mui/material"
import { CustomTabPanel, a11yProps } from "../../components/common/CustomTabPanel"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Pending from "../../components/myAppointments/Pending"
import Scheduled from "../../components/labtest/Scheduled"
import CollectedTable from "../../components/labtest/CollectedTable"
import ReportReceived from "../../components/labtest/ReportReceived"
import Requested from "../../components/labtest/RequestedTable"
import Cancelled from "../../components/labtest/Cancelled"
import UploadedPrescription from "../../components/labtest/UploadedPrescription"

const LabTests = () => {
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2>Lab test request</h2>
                </div>
                <div className={styles.appointmentsMain}>
                    <Tabs value={value} onChange={handleChange} className="custumTabs">
                        <Tab label="Requested" {...a11yProps(0)} />
                        <Tab label="Scheduled" {...a11yProps(1)} />
                        <Tab label="Cancelled" {...a11yProps(2)} />
                        <Tab label="Collected" {...a11yProps(3)} />
                        <Tab label="Report received" {...a11yProps(4)} />
                        <Tab label="Uploaded Prescription" {...a11yProps(5)} />
                    </Tabs>
                    <CustomTabPanel value={value} index={0}>
                        <Requested />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Scheduled />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <Cancelled />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <CollectedTable />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={4}>
                        <ReportReceived />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={5}>
                        <UploadedPrescription />
                    </CustomTabPanel>
                </div>
            </div>
        </CustomContainer>
    )
}

export default LabTests